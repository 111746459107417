import consumer from "./consumer";

App.command_channel = consumer.subscriptions.create("CommandChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("command channel");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    switch (data["command"]) {
      case "reload":
        location.reload();
        break;
      default:
        console.log("unknown command received", data);
    }
  },
});
