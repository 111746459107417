import { Controller } from "stimulus";

const messages = [
  "",
  "Seriously⁉️",
  "You're fine",
  "Frog in your throat",
  "Sniffles",
  "Just stay home…",
  "OMGzz you have a cold",
  "Maybe go to the hospital. Or take a nap",
  "Teh COVID",
  "Logic and Reason",
];

export default class extends Controller {
  static targets = ["message"];

  connect() {
    this.messageTarget.innerHTML = `<h1>${messages[0]}</h1>`;
  }

  update(event) {
    let message = messages[event.target.value];
    this.messageTarget.innerHTML = `<h1>${message}</h1>`;
  }
}
