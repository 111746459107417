import consumer from "./consumer";

App.room_channel = consumer.subscriptions.create("RoomChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("room channel");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    var newNode = document.createElement("div");
    newNode.innerHTML = data.message;

    var list = document.getElementById("messages");
    list.prepend(newNode.children[0]);

    updatePageTitle();
  },

  speak: function (message) {
    return this.perform("speak", { message: message });
  },
});

function updatePageTitle() {
  var title = document.getElementById("title-title");

  if (title.innerText == "The GregBook") {
    title.innerText = "The GergBook";
  } else {
    title.innerText = "The GregBook";
  }
}

document.addEventListener("DOMContentLoaded", function () {
  var button = document
    .getElementById("message-input")
    .querySelector("input[type=submit]");

  button.closest("form").addEventListener("submit", function (event) {
    var input = button.parentNode.querySelector("textarea");
    App.room_channel.speak(input.value);
    input.value = "";
    event.preventDefault();

    setTimeout(function () {
      button.removeAttribute("disabled");
    }, 500);
  });
});
